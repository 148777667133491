body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica', 'Arial';
  background-color: #1d1d1d;
  color: #e6e6e6;
}

section{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 20pt;
}

h2 {
  font-size: 36pt;
}

h4 {
  font-size:18pt;
}

.cardP {
  font-size:14pt;
}

#logo {
  width: 50%;
}

.container {
  max-width: 100%;
}

.header {
  display: flex;
  justify-content: center;
}

button {
  height: 80px;
  max-width: 180px;
  min-width: 180px;
  font: bold 14pt Helvetica;
  margin: 1vw
}

.textDiv {
  text-align: center;
  padding: 5% 20%;
}

.textDiv2 {
  text-align: center;
  padding: 5%;
}

.cards {
  display: flex;
}

.textCard {
  background: #e6e6e6;
  color: #1d1d1d;
  margin: 1vw;
  min-width: 12vw;
  border-radius: 10px;
  padding: 2%;
}

.buttons{
  display:flex;
  justify-content:center;
}

@media (max-width:900px){
  .cards{
    flex-direction: column;
  }
}

.reveal{
  position:relative;
  transform: translateY(30vh);
  opacity: 0;
  transition: all 1s ease;
}

.reveal.active{
  transform: translateY(0vh);
  opacity: 1; 
}